import * as React from 'react'
import {object as PTObject} from 'prop-types'
import {inject, observer} from 'mobx-react'
import {
  Alert,
  Collapse,
  Modal,
  Nav,
  Navbar as BSNavbar,
} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import {Motion, spring} from 'react-motion'
import { withRouter } from 'react-router';


import {TOKEN_TIMEOUT_GRACE} from 'globals'
import API from 'api'
import initializeStores from 'stores'
import {UserStore} from 'stores/userStore'
import {AlertStore} from 'stores/alertStore'
import {AppStateStore} from 'stores/appStateStore'
import Auth from 'modules/auth'
import AdminPanelOpener from 'modules/admin/Opener'
import AlertComponent from './AlertComponent'
import ProfileIcon from './ProfileIcon'
import ProfileEditModal from './ProfileEditModal'
import NotificationIcon from './NotificationIcon'

const LogoHex = require('images/elements/mb-bar-hex.svg')
const LogoText = require('images/elements/mb-bar-text.svg')
const ExternalLink = require('images/elements/external-link.svg')

const Navbar = BSNavbar as any

interface Context {
  router: {
    push: (context: string) => void
  } // temporary solution
}

interface Props {
  userStore?: UserStore
  alertStore?: AlertStore
  appStateStore?: AppStateStore
}

// TODO: let's put some better types on this
interface State {
  navIndex: number
  startNavIndex: number
  loggedIn?: any
  timeoutTimer?: any
  timeoutModalShowing?: boolean
  timeoutModalAlertShowing?: boolean
  notificationPanel?: any
  closeNotificationPanel?: any
  showProfileEditModal: boolean
}

const NAV_IDX_RE = /^(\/[^\/]*).*$/

const NAV_INDICES = {
  '/screens': 0,
  '/playlists': 1,
  '/content': 2,
  '/help': 3,
}

@inject('userStore', 'alertStore', 'appStateStore')
@observer
class App extends React.Component<any, any> {
  // static contextTypes = {
  //   router: PTObject.isRequired,
  // }

  context: Context

  constructor(props: any, context: Context) {
    super(props)

    this.state = {
      navIndex: NAV_INDICES[props.location.pathname.replace(NAV_IDX_RE, '$1')],
      startNavIndex:
        NAV_INDICES[props.location.pathname.replace(NAV_IDX_RE, '$1')],
      loggedIn: Auth.loggedIn(),
      timeoutTimer: Auth.makeTokenTimer(this.timeoutExpiration),
      timeoutModalShowing: false,
      timeoutModalAlertShowing: false,
      notificationPanel: null,
      closeNotificationPanel: null,
      showProfileEditModal: false,
    }

    Auth.onChange = this.updateAuth = this.updateAuth.bind(this)
  }

  // UPDATE-TODO:
  // Move code with side effects to componentDidMount, and set initial state in the constructor.
  UNSAFE_componentWillMount() {
    API.loadToken()
    initializeStores()
    this.props.appStateStore.navbarExpanded = false
  }

  // UPDATE-TODO:
  // Move code with side effects to componentDidMount, and set initial state in the constructor.
  // UNSAFE_componentWillReceiveProps(nextProps) {
  componentDidUpdate(prevProps, prevState: State) {
    if(prevState.navIndex !== NAV_INDICES[this.props.location.pathname.replace(NAV_IDX_RE, '$1')] || prevState.loggedIn !== this.state.loggedIn || prevState.notificationPanel !== this.state.notificationPanel || prevState.closeNotificationPanel !== this.state.closeNotificationPanel)
    {
      this.setState({
        navIndex:
          NAV_INDICES[this.props.location.pathname.replace(NAV_IDX_RE, '$1')],
        loggedIn: Auth.loggedIn(),
        notificationPanel: null,
        closeNotificationPanel: null,
      })
    } 
  }

  timeoutExpiration = () => {
    // show LogInTimeoutModal window, and set the timer to log them out
    console.log('Close to logout expiration, alerting!')
    this.setState({
      timeoutTimer: setTimeout(
        () => this.signOut(),
        Auth.getTokenTTL(window.localStorage.token)
      ),
      timeoutModalShowing: true,
    })
  }

  refreshTokenModal = () => {
    // hide LogInTimeoutModal window, refresh the token, and reset the timer
    Auth.refreshToken((success) =>
      success
        ? this.setState({
            timeoutTimer: Auth.makeTokenTimer(this.timeoutExpiration),
            timeoutModalShowing: false,
          })
        : this.setState({
            timeoutModalAlertShowing: true,
          })
    )
  }

  updateAuth = (loggedIn: any) =>
    this.setState({
      loggedIn,
    })

  notificationIconClicked = (panel: any, closer: any) =>
    this.setState({
      notificationPanel: panel,
      closeNotificationPanel: closer,
    })

  openProfileEdit = () => this.setState({showProfileEditModal: true})

  closeTimeoutModal = () =>
    this.setState({
      timeoutModalShowing: false,
    })

  signOut = () => {
    this.closeTimeoutModal()
    console.log('Signed out')
    if (Auth.logout(null)) {
      this.props.history.push('/')
    }
  }

  get shouldRenderTouch(): boolean {
    return (
      this.props.userStore.currentUser &&
      (this.props.userStore.currentUser.isAdmin ||
        Boolean(this.props.userStore.currentUser.touch_dirs.length))
    )
  }

  onNavbarToggle = (opened: boolean) => {
    this.props.appStateStore.navbarExpanded = opened

    if (opened) {
      $('.side-nav-col').addClass('navbar-is-expanded')
    } else {
      $('.side-nav-col').removeClass('navbar-is-expanded')
    }
  }

  render() {

    let startElement = document.getElementById(`site-nav-${this.state.startNavIndex}`)
    let curElement = document.getElementById(`site-nav-${this.state.navIndex}`)
    let navElement = document.getElementById("mb-site-nav")

    return (
      <div>
        <Navbar
          variant="dark"
          collapseOnSelect
          onToggle={this.onNavbarToggle}
          expanded={this.props.appStateStore.navbarExpanded}
        >
          <div className="container">
            <div className="navbar-header">
              <Navbar.Brand>
                <Link className="minerbytes-logo-link" to="/">
                  <div id="minerbytes-logo">
                    {/* UPDATE_TODO - fix logo-text*/}
                    <LogoText id="logo-text" />
                  </div>
                </Link>
              </Navbar.Brand>
              <NotificationIcon
                sendNotificationPanel={(panel, closer) =>
                  this.notificationIconClicked(panel, closer)
                }
              />
              {this.state.notificationPanel}
              <ProfileIcon
                openProfileEdit={this.openProfileEdit}
                signOut={this.signOut}
              />
              <Navbar.Toggle />
            </div>
            <Navbar.Collapse>

              {/* pullRight moved to nav header */}
              {/* <Nav className="mb-nav" style={
                {
                  right: touchPositionConstants[3] + widthConstants[4]
                  // width: widthConstants[this.state.startNavIndex],
                }
              }> */}
              <Nav id="mb-site-nav" className="mb-nav">
                  <Nav.Item id="site-nav-0">
                    <Link  to="/screens" className={this.state.navIndex === 0 ? "active" : "inactive"}>
                      Screens
                    </Link>
                  </Nav.Item>
                  <Nav.Item id="site-nav-1">
                    <Link to="/playlists" className={this.state.navIndex === 1 ? "active" : "inactive"}>
                      Playlists
                    </Link>
                    </Nav.Item>
                  <Nav.Item id="site-nav-2">
                    <Link to="/content" className={this.state.navIndex === 2 ? "active" : "inactive"}>
                      Content
                    </Link>
                  </Nav.Item>
                  <Nav.Item id="site-nav-3">
                    <Link to="/help" className={this.state.navIndex === 3 ? "active" : "inactive"}>
                      Help
                    </Link>
                  </Nav.Item>
                {this.shouldRenderTouch && <TouchButton/>}
              { (startElement && curElement) ? 
              <Motion
                defaultStyle={{
                  right: navElement.getBoundingClientRect().right - startElement.getBoundingClientRect().right - 10,
                  width: startElement.offsetWidth + 20
                }}
                style={{
                  right: spring(
                    navElement.getBoundingClientRect().right - curElement.getBoundingClientRect().right - 10
                  ),
                  width: spring(curElement.offsetWidth + 20),
                }}
              >
                {(move) => <div className="active-indicator" style={move} />}
              </Motion> 
              : null
              }
              </Nav>
            </Navbar.Collapse>
          </div>
        </Navbar>

        <div id="aalertcontainer-container">
          <div id="aalertcontainer">
            {this.props.alertStore.alerts.map((alert) => (
              <AlertComponent alert={alert} key={alert.uid} />
            ))}
          </div>
        </div>
        {/* <div className="row" id="wrapper">
          {this.props.children}
        </div> */}
        <AdminPanelOpener />
        <Modal
          show={this.state.timeoutModalShowing}
          onHide={this.closeTimeoutModal}
        >
          <Modal.Header>
            <h4 className="modal-title">Log In Timeout</h4>
          </Modal.Header>
          <Modal.Body>
            Due to inactivity, you will be logged out in{' '}
            {TOKEN_TIMEOUT_GRACE / 60000} minutes.
          </Modal.Body>
          <Modal.Footer>
            <button type="button" onClick={this.closeTimeoutModal}>
              Close
            </button>
            <button
              type="button"
              className="btn btn-default btn-primary"
              onClick={this.refreshTokenModal}
            >
              Refresh Token
            </button>
          </Modal.Footer>
          <Collapse in={this.state.timeoutModalAlertShowing}>
            <Alert variant="warning">Token refresh failed.</Alert>
          </Collapse>
        </Modal>

        <ProfileEditModal
          closeModal={() =>
            this.setState({
              showProfileEditModal: !this.state.showProfileEditModal,
            })
          }
          user={this.props.userStore.currentUser}
          show={this.state.showProfileEditModal}
        />
      </div>
    )
  }
}

function currentENV() {
  if (process.env.NODE_ENV === 'production') {
    switch (process.env.MB_ENV) {
      case 'beta': // Beta (T3) domains
        return 'minerbytes-t3.managed.mst.edu'
      case 'development': // 'dev' (D3) domains
        return 'minerbytes-d3.managed.mst.edu'
      case 'devd4': // 'dev' (D4) domains
        return 'minerbytes-d4.managed.mst.edu'
      case 'testt4': // 'test' (T4) domains
        return 'minerbytes-t4.managed.mst.edu'
      case 'testt2': // 'test' (T2) domains
        return 'minerbytes-t2.managed.mst.edu'
      default:
        // production domains
        return 'minerbytes.mst.edu'
    }
  } else {
    return 'minerbytes-v3.local.mst.edu:7000'
  }
}

const TouchButton = () => {
  return (
    <div className="mb-nav-touch-btn nav-item">
      <a href={`//${currentENV()}/touch-config`} target="_blank">
        touch
        <ExternalLink className="external-link" width="16" height="16" />
      </a>
    </div>
  )
}

export default withRouter(App)

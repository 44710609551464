import { DirectoryPermissions } from './api/resources'

export type AssetTypeString = 'IMAGE' | 'WEB' | 'VIDEO'
export type OrientationString = 'vertical' | 'horizontal'
export type ValidationState = 'success' | 'warning' | 'error' | null

export type ScreenPermissionLevel = 'owner' | 'editor' | 'viewer'
export type PlaylistPermissionLevel = 'creator' | 'editor' | 'viewer'
export type PermissionLevel = ScreenPermissionLevel | PlaylistPermissionLevel

export type DateString = string // "YYYY-MM-DD"
export type TimeString = string // "hh:mm:ss"
export type DateTimeString = string // "YY-MM-DD hh:mm:ss"

export interface SelectOption {
  value: string
  label: string
}

export interface PlaylistOption {
  title: string
  uniqueID: string
  hasAsset: boolean
}

export interface LatLngType {
  lat: number
  lng: number
}

export function nameToFnameLname(name: string): [string, string] {
  const idx = name.lastIndexOf(' ')

  if (idx === -1) {
    return [name, '']
  }

  return [name.slice(0, idx), name.slice(idx + 1)]
}

export function fnameLnameToName(fname: string, lname: string): string {
  if (fname && lname) {
    return `${fname} ${lname}`
  }
  return `${fname || ''}${lname || ''}`
}

export const SandTLATLNG: LatLngType = Object.freeze({
  lat: 37.955,
  lng: -91.774
})

/* ModelInterfaces:
    The following interfaces describe the models implemented in the stores.
*/

export interface BroadcastInterface {
  id: string
  user_id: string
  override: 0 | 1
  message_text: string
  screenIDs: string[]
}

export interface AssetInterface {
  id: string
  verticalURL?: string | null
  verticalFileID?: string | null
  verticalFile?: object | null
  horizontalURL?: string | null
  horizontalFileID?: string | null
  horizontalFile?: object | null
  webURL?: string | null
  webFileID?: string | null
  youtubeID?: string | null
  youtubeFileID?: string | null
  lifeSpanBegin: DateTimeString
  lifeSpanEnd: DateTimeString
  title: string
  orientation: OrientationString | null
  ownerID: string
  description: string
  assetType: AssetTypeString
  isFavorite: boolean
  isPrivate: boolean
}

export interface InstitutionInterface {
  id: string
  name: string
  imgURL: string
  webURL: string | null
}

export interface NotificationInterface {
  id: string
  userID: string
  itemID: string
  code: number
  messageText: string
  cleared: boolean
  link: string | null
}

export interface PlaylistInterface {
  id: string
  title: string
  ownerID: string
  orientation: OrientationString | null
  inUse: boolean
  promoted: boolean
  assetIDs: string[]
  sharedIDs: string[]
}

export interface ScreenInterface {
  id: string
  title: string
  ownerID: string
  activePlaylistID: string
  orientation: OrientationString | null
  last_req: Date | null
  marquee: string | null
  mq_created: Date
  mq_duration: number
  location: string
  playlistIDs: string[]
  institution: string
  department: string
  sharedIDs: string[]
  lat: number
  lon: number
}

export interface ShowtimeInterface {
  id: string
  screenID: string
  playlistID: string
  day_start: number
  time_start: TimeString
  duration: number
}

export interface UserInterface {
  id: string
  broadcast_priv: 0 | 1
  username: string
  fname?: string
  lname?: string
  email: string
  active: boolean
  imageURL: string
  isAdmin: boolean
  institutionID: string
  department: string
  sysAdmin: string
  touch_dirs: DirectoryPermissions[]
}

export const DOMAIN_PROTO = process.env.DOMAIN_PROTO
export const ROOT_DOMAIN = process.env.ROOT_DOMAIN
export const BACKEND_DOMAIN = process.env.BACKEND_DOMAIN
export const NOBACKEND = process.env.NOBACKEND
export const MSFT_CLIENT_ID = process.env.MSFT_ID
export const GOOGLE_MAPS_KEY = process.env.GMAPS_KEY

export const OAUTH2_CALLBACK_URL = `${DOMAIN_PROTO}://${ROOT_DOMAIN}/oauth2callback`
export const GOOGLE_OAUTH2_URL = `https://login.microsoftonline.com/e3fefdbe-f7e9-401b-a51a-355e01b05a89/oauth2/v2.0/authorize?client_id=${MSFT_CLIENT_ID}&redirect_uri=${encodeURIComponent(
  OAUTH2_CALLBACK_URL
)}&scope=openid%20email%20profile&response_type=code&response_mode=fragment`

// milliseconds to warn the user that their token is expiring soon
export const TOKEN_TIMEOUT_GRACE = 5 * 60000

let localStorageChecked = null
/**
 * check that localStorage is available. If not, try setting it to
 * sessionStorage. Result is cached so function can be recalled.
 */
export function localStorageCheck(): boolean {
  if (localStorageChecked !== null) {
    return localStorageChecked
  }

  try {
    window.localStorage.setItem('mb', 'mb')
    window.localStorage.removeItem('mb')
  } catch (exc) {
    // localStorage not available, try to replace with sessionStorage
    try {
      Object.defineProperty(window, 'localStorage', { value: sessionStorage })
      window.localStorage.setItem('mb', 'mb')
      window.localStorage.removeItem('mb')
    } catch (exc2) {
      // it's not happening.
      localStorageChecked = false
      return false
    }
  }
  localStorageChecked = true
  return true
}
localStorageCheck()

export default {}
